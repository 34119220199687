<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        If the
        <chemical-latex content="H^+" />
        ion concentration in an aqueous solution is
        <number-value :value="concentration" unit="\text{M,}" />
        then the pH is:
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'OleMiss116_PL7_Q7part3',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
  },
};
</script>
